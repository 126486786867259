<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contentBox">
        <div class="flex title ju-between">
          <div class="m-t1">{{ item.title }}</div>
          <div>
            <span v-if="item.num" class="cur-p" @click="open(null,item)">
              <CountTo color="#0066CC" fontSize="18px" :endVal="item.num" />
            </span>
          </div>
        </div>
        <div>
          <div v-if="item.subTitle" class="tips">
            {{ item.subTitle }}
          </div>
          <div class="flex wrap">
            <div v-for="opt in item.subItemList" :key="opt.id" class=" m-r2 item">
              {{ opt.title }}
              <span>
                <span @click="open(opt,item)" class="cur-p" :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>
              </span>
              {{ opt.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" ref="TableRef" :title="title" @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk"  @searchChange="searchChange" :colorIdx="colorIdx" :item="itemRemake" @changeBtn="changeBtn" :loading="loading" :pagination="false"
       :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" keyType="pageType" @changePagination="changePagination" :isOrder="isOrder" :iptData="iptData"  :columns="columns" :data="dataSource" :totalClueNum="totalClueNum" :subItemList="subItemListData" :isAll="isAll">
      </Table>
    </template>

  </div> 
</template>

<script setup>
import { ref, reactive } from 'vue'
import { colorType } from '@/utils/enum.js'
import CountTo from '@/components/countTo'
import Table from '../table'
import { documentaryPage } from '@/api/transport/documentary'
import { employeeList } from '@/api/crmManagement/setRules'
import { IfNull, debounce } from '@/utils/util'
import {
  orderiptData1,ordercolumns1, orderiptData2, ordercolumns2, orderiptData3,ordercolumns3
} from './keyIssues'
import { receivableTotal, financeCenterEmp } from '@/api/financialCenter/financialCenter'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
})
const TableRef = ref(null)
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const itemRemake = ref({})
const loading = ref(false)
const data = ref([])
const iptData = ref([])
const columns = ref([])
const dataSource = ref([])
const subItemListData = ref([])
const totalClueNum = ref(0)
const isOrder = ref(false)
const isAll = ref(true)
const pageSize = ref(50)
const getUrl = ref(null)
const recordType = ref({})
const searchMsg = ref({})
const uniqueArrList = ref([])
const state = reactive({
  timer: null
})

const searchChange = (e) => {
  if (!IfNull(e)) return
  debounce(state, getEmployeeList, 1000, e)
}
const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      iptData.value.forEach(opt => {
        if (opt.prop === 'saleId') {
          opt.opt = list
        }
      })
    }
  })
}
const resetBtn = () => {
  searchMsg.value = {}
  pageSize.value = 50
  getData(recordType.value.pageType, recordType.value.colorType)
}
const searchAdd = (e) => {
  searchMsg.value = e
  pageSize.value = 50
  getData(recordType.value.pageType, recordType.value.colorType)
}



const setRemaekOk = () => {

}

const changeBtn = (e, v) => {
  getData(v, e)
}

const open = (opt, item) => {
  searchMsg.value = {}
  isAll.value = true
  if (item.subItemList) {
    subItemListData.value = item.subItemList
  } else {
    subItemListData.value = []
  }
  totalClueNum.value = item.num
  if (!opt) {
    colorIdx.value = null
  } else {
    colorIdx.value = opt.pageType
  }
  itemRemake.value = item
  let colorType = opt ? opt.pageType : null
  processingData(item.pageType, colorType)
  title.value = item.title
  visible.value = true
}
const processingData = (pageType, colorType) => {
  switch (pageType) {
    case 100:
      iptData.value = orderiptData1.value
      columns.value = ordercolumns1.value
      x.value = 0
      getUrl.value = receivableTotal
      break;
      case 200:
      iptData.value = orderiptData2.value
      columns.value = ordercolumns2.value
      x.value = 0
      getUrl.value = receivableTotal
      break;
    case 300:
      iptData.value = orderiptData3.value
      columns.value = ordercolumns3.value
      x.value = 0
      getUrl.value = financeCenterEmp
      break;
  }
  setOpt()
  getData(pageType, colorType)
}
const getData = (pageType, colorType) => {
  if (getUrl.value) {
    pageSize.value = 50
    loading.value = true
    dataSource.value = []
    getUrl.value({
      ...searchMsg.value,
      subType: colorType || pageType
    }).then(res => {
      if (res.code !== 10000) return
      recordType.value.pageType = pageType
      recordType.value.colorType = colorType
      data.value = res.data
      dataSource.value = data.value.slice(0, pageSize.value)
    }).finally(() => {
      loading.value = false
    })
  }
}
const setOpt = () => {
  iptData.value.forEach(item => {
    if (item.prop === 'orderSettlement') {
      item.opt = store.state.enumAll.orderSettlementList
    }
    if (item.prop === 'orgId') {
      item.opt = store.state.app.orgList
    }
    if (item.prop === 'exceptionType') {
      item.opt = store.state.enumAll.exceptionExplain
    }
    if (item.prop === 'saleId') {
      item.opt = []
    }
    if (item.prop === 'intentionLevel') {
      item.opt = store.state.app.labelAll.INTENTION_LEVEL
    }
    if (item.prop === 'settlementMode') {
      item.opt = store.state.enumAll.CustomerSettlementEnum
    }
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
</script>

<style lang="less" scoped>
.contentBox {
  margin-top: 10px;
  padding: 10px;
  background: #edf0f9;
  border-radius: 4px;
}

.title {
  color: #333;
  font-size: 13px;
}

.tips {
  color: #999;
  font-size: 12px;
}

.item {
  color: #344563;
  font-size: 12px;
}
</style>